import React, {useEffect} from "react";
import { useWindowSize } from "@uidotdev/usehooks";
import Confetti from "react-confetti";
import "./birthday.css"

function App() {
  const size = useWindowSize();

  useEffect(() => {
    // This will run when the page first loads and whenever the title changes
    document.title = "Gelukkige verjaardag!";
  }, []);


  return (
    <div className="App">
      <main>
      <Confetti
          width={size.width?? 1000}
          height={size.height ?? 1000}
      />
      <header className="App-header">
        <h1>Gelukkige verjaardag!</h1>
      </header>
      
      <h2>Proficiat Jori!</h2>
      <p>Speciaal voor je verjaardag<br />
      gezondheid, goede wensen<br />
      de mooie dingen van het leven<br />
      omringd door lieve mensen<br />
      We wensen je vreugde en geluk<br />
      liefde, plezier en<br />
      in gedachten zijn we bij je<br />
      om deze dag te vieren</p>

      <h3>Eli, Enya en Aki🐾</h3>
      </main>
    </div>
  );
}

export default App;
